import React from 'react';


export default class GESearchResultsPagination extends React.Component {
	
	constructor(props) {
    	super(props);
    	
    	this.state={
    		GetResponseData:[]
    	}
    	this.ResponseDataInfo=[];
	}



	recordPrevious(pagecount,domain){
		
		this.props.previous(pagecount,domain);
	}
	recordNext(pagecount,domain){
		this.props.next(pagecount,domain);
	}

	render(){		
		return(
			this.props.totalpages > 0  ?				
				<div className="col-12 col-lg-6">
					<div className="pagination-wrapper">
						<ul className="pagination pagination-lg">
						  <li className="page-item">
						  	<span className="page-link icon ficon-sm-arrow-lft" 
						  		onClick={() => this.recordPrevious(this.props.data.info.page.current_page,
						  			this.props.domainfilter)} >
						  	</span>
						  </li>
						  <li className="page-item result-summary mx-auto">
						  	<h5 className=".body-3 ">
						  		{ this.props.current_page } / { this.props.totalpages } 
						  	</h5>
						  </li>
						  <li className="page-item">
						  	<span className="page-link icon ficon-sm-arrow-rt" 
						  		onClick={() => this.recordNext(this.props.data.info.page.current_page,
						  			this.props.domainfilter)} >
						  	</span>
						  </li>
						</ul>
					</div>
				</div>
			:
			null
		);
	}

}

 