import React from 'react';

export default class GESolarHeroHeader extends React.Component {

  openLinks(event){
    if (event.currentTarget.attributes['data-link-src']){
      window.xprops.props.Openlinks(event.currentTarget.attributes['data-link-src'].value);
    }
  }

  render() {
    var imgBackground;
    if (this.props.solarheaderimg ) {
      if (this.props.solarheaderimg.sm_field_hero_image_url){
        imgBackground = {
          backgroundImage: "url(" + this.props.baseImgUrl + this.props.solarheaderimg.sm_field_hero_image_url[0] + ")"
        };
      }
    }

    return(
      this.props.solarheaderimg.sm_field_hero_image_url ?
        <div className = "ge-solr-header-img" style = {imgBackground}>
          <div className = "ge-solr-header-desc-gradient"></div>
          <div className = "ge-solr-header-desc">
            {this.props.solarheaderimg.tm_X3b_en_field_hero_title ? this.props.solarheaderimg.tm_X3b_en_field_hero_title &&
              (<h3>{this.props.solarheaderimg.tm_X3b_en_field_hero_title}</h3>)
              :
              null
            }
            {this.props.SolarData ?
              (<div className = "body-2">
                {(typeof this.props.SolarData[0].tm_X3b_en_field_hero_html_body === 'string' || this.props.SolarData[0].tm_X3b_en_field_hero_html_body instanceof String) &&
                  (<p>{this.props.SolarData[0].tm_X3b_en_field_hero_html_body.substring(0,80)} ...</p>)
                }

                {this.props.solarheaderimg.tm_X3b_en_field_hero_field_link && this.props.solarheaderimg.tm_X3b_en_field_hero_field_link_title ? this.props.solarheaderimg.tm_X3b_en_field_hero_field_link[0] &&
                  (<div className = "card-links" >
                    <h6 data-link-src = {this.props.solarheaderimg.tm_X3b_en_field_hero_field_link[0]} onMouseDown = {this.openLinks}>
                      {this.props.solarheaderimg.tm_X3b_en_field_hero_field_link_title[0]}
                    </h6>
                  </div>)
                  :
                  null
                }

                {this.props.solarheaderimg.tm_X3b_en_field_hero_field_link && this.props.solarheaderimg.tm_X3b_en_field_hero_field_link_title ? this.props.solarheaderimg.tm_X3b_en_field_hero_field_link[1] &&
                  (<div className = "card-links">
                    <h6 data-link-src = {this.props.solarheaderimg.tm_X3b_en_field_hero_field_link[1]} onMouseDown = {this.openLinks}>
                      {this.props.solarheaderimg.tm_X3b_en_field_hero_field_link_title[1]}
                    </h6>
                  </div>)
                  :
                  null
                }
              </div>)
            :
            null
          }
        </div>
      </div>
      :
      null
    )
  }
}



