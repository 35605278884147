import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default class SkeletonCards extends React.Component {
	
	
	render(){
			
		return(
			<React.Fragment >
				<div className="results-header caption"> 
					<Skeleton />
				</div>
				<div className="card" >
			    	<div className="card-body">
						<div className="card-title">
							<span><h3 ><Skeleton /></h3></span>
						</div>
			      		<Skeleton count={3} />		      		
					</div> 
				</div>
				<div className="card" >
			    	<div className="card-body">
						<div className="card-title">
							<span><h3 ><Skeleton /></h3></span>
						</div>
			      		<Skeleton count={3} />		      		
					</div> 
				</div>
				<div className="card" >
			    	<div className="card-body">
						<div className="card-title">
							<span><h3 ><Skeleton /></h3></span>
						</div>
			      		<Skeleton count={3} />		      		
					</div> 
				</div>
				<div className="card" >
			    	<div className="card-body">
						<div className="card-title">
							<span><h3 ><Skeleton /></h3></span>
						</div>
			      		<Skeleton count={3} />		      		
					</div> 
				</div>
			</React.Fragment >
		);
	}

}

 