import React from 'react';

const urlExternalReports=  process.env.REACT_APP_EXTERNALSEARCH_REPORTS;
const urlExternalPress = process.env.REACT_APP_EXTERNALSEARCH_PRESS;
const urlExternalResearch = process.env.REACT_APP_EXTERNALSEARCH_RESEARCH;

export default class GEExternalLinksHeader extends React.Component {
  
  openLinks = (event) => {
    
		if (event.currentTarget.attributes['data-link-src']){
		  window.xprops.props.redirectlinks(event.currentTarget.attributes['data-link-src'].value);	
    }
	}


  render() {
    return(
      <React.Fragment>
        <div className="btn button-primary">GLOBAL SEARCH ACROSS GE</div>
        <button type="button"
          className= "btn button-primary" 
          data-link-src = { urlExternalReports + this.props.searchtext}
          onClick={ this.openLinks } >
          SEARCH FOR REPORTS
        </button>	

        <button type="button"
          className= "btn button-primary"
          data-link-src = { urlExternalPress + this.props.searchtext}
          onClick={ this.openLinks } >
          SEARCH FOR PRESS RELEASES
        </button> 

         <button type="button"
          className= "btn button-primary"
          data-link-src = { urlExternalResearch + this.props.searchtext}
          onClick={ this.openLinks } >
          SEARCH FOR RESEARCH
        </button> 

      </React.Fragment>
	  )

  }


}



