import React from 'react';
import Skeleton from 'react-loading-skeleton';
import geDomainFilters from './domainFilters.json';
var langCode = 'en';

export default class GESearchDomainFilter extends React.Component {

  constructor(props) {
    super(props);
    this.STresponseData = [];
    this.Dataloaded = false;
    this.domainFilters = geDomainFilters;
    this.state = {
      filterResultsLabel: 'Filter results',
      GetResponseData: [],
      resultbydomain: null,
      STresponseData: [],
      Dataloaded: false
    }

    if (window.xprops){
      if (window.xprops.props.GELangCode) {
        langCode = window.xprops.props.GELangCode;
      }
    }
  }

  componentDidUpdate(){
    if ((langCode) && (langCode !== 'en')) {
      (async () => {
        // Dynamically imported module (runtime)
        const { translateData } = await import("./geTranslationUtils");

        translateData(langCode, this.state.filterResultsLabel)
        .then((data) => {
          this.setState({ filterResultsLabel: data })
        });
      })();
    }


    if (this.props.STresponseData.records){
      if (this.state.GetResponseData !== this.props.STresponseData.records.page){
        this.getDomainInfo();
        this.setState({GetResponseData: this.props.STresponseData.records.page});
      }
    }
  }

  FilterbyDomain(domain){
    this.props.FilterbyDomain(domain);
  }

  getDomainInfo(){
    var searchURL = "/resultsdomain?keywords=" + encodeURIComponent(this.props.searchtext.replace(/[^\p{L}\p{N}\p{Sc}_@&.+\- ]/gui, '')) + "&currentpage=1";
    fetch(searchURL)
    .then(response => response.json())
    .then((data) => {
      this.STresponseData = data;
      this.setState({Dataloaded: true})
    }).catch(error => console.log('error', error));
  }

  render(){
    var p = [];

    if (this.state.Dataloaded === true){
      if (this.STresponseData){
        p = this.STresponseData.info.page.facets["domain-identifier"];
      }
    }

    return(
      this.state.Dataloaded === true ?
        this.STresponseData.info && this.STresponseData.info.page.total_result_count > 0 ?
          <div className = "FilterbyDomain-wrapper">
            <h3>{ this.state.filterResultsLabel }</h3>
            <button type = "button"
              className = {"btn button-secondary-light" +
                (
                  this.props.domainfilter == null ? " active" : ""
                )
              }
              onClick = {() => this.FilterbyDomain(null)}>
              All ({this.STresponseData.info && this.STresponseData.info.page.total_result_count})
            </button>
            {
              this.domainFilters.length > 0 ?
                this.domainFilters.map((item, i) => (
                  <button type = "button"
                    className = {"btn button-secondary-light" +
                      (
                        this.props.domainfilter === item.domainFilter ? " active" : ""
                      )
                    }
                    onClick = {() => this.FilterbyDomain(item.domainFilter)}
                    disabled = {p[item.domainFilter] ? false : true}>
                    {item.label} ({p[item.domainFilter] ? p[item.domainFilter] : 0 })
                  </button>
                ))
              :
              null
            }
          </div>
          :
          null
        :
        <div className = "FilterbyDomain-wrapper">
          <h3><Skeleton /></h3>
          <Skeleton count = {3} />
        </div>
    );
  }
}


