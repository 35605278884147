import React from 'react';
import GEForm from './geForm'

function App(props) {

  return (
    <GEForm {...window.xprops}/>
  );
}

export default App;
