import React from 'react';
var langCode = 'en';

export default class GEBackComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      backBtnText: 'BACK',
      LoadingData: false
    }

    if (window.xprops){
      if (window.xprops.props.GELangCode) {
        langCode = window.xprops.props.GELangCode;
      }
    }
  }

  componentDidMount(){
    this.setState({ LoadingData: this.props.DataLoaded});

    if ((langCode) && (langCode !== 'en')) {
      (async () => {
        // Dynamically imported module (runtime)
        const { translateData } = await import("./geTranslationUtils");

        translateData(langCode, this.state.backBtnText)
        .then((data) => {
          this.setState({ backBtnText: data })
        });
      })();
    }
  }

  BackComponent(){
    this.props.HistoryBackSearch();
  }

  render(){
    return(
      <div className="close-search-app mt-0">
        <div className="card-links" onClick={() => this.BackComponent()} >
          <h6>{ this.state.backBtnText }</h6>
        </div>
      </div>
    );
  }
}
