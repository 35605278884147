import React from 'react';
import GEQuicklinksdata from './geQuicklinksData';
var langCode = 'en';

class GESearchNoResultData extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      noResultsLabel: 'No results were found across GE.com for',
      noResultsNote: 'Try using different search terms or try the same search within GE Reports or Press releases. Alternatively, use a popular search or a Quicklink.'
    };

    if (window.xprops){
      if (window.xprops.props.GELangCode) {
        langCode = window.xprops.props.GELangCode;
      }
    }
  }

  componentDidMount(){
    if ((langCode) && (langCode !== 'en')) {
      (async () => {
        // Dynamically imported module (runtime)
        const { translateData } = await import("./geTranslationUtils");

        translateData(langCode, this.state.noResultsLabel)
        .then((data) => {
          this.setState({ noResultsLabel: data })
        });

        translateData(langCode, this.state.noResultsNote)
        .then((data) => {
          this.setState({ noResultsNote: data })
        });

      })();
    }
  }

  SelectPopularSearch(selectedRecent){
    this.props.SelectPopularSearch(selectedRecent);
  }
  render() {
    return (
      <div className="no-results-wrapper body-2">
        { this.state.noResultsLabel } <strong>{this.props.searchtext}</strong>
        <p>{ this.state.noResultsNote }</p>

        <GEQuicklinksdata
          hideRecent={true}
          SelectPopularSearch={this.SelectPopularSearch.bind(this)}
        />

      </div>
    );
  }
}

export default GESearchNoResultData

