import React from 'react';
var langCode = 'en';

export default class GECloseComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      closeBtnText: 'CLOSE SEARCH',
      LoadingData: false
    }

    if (window.xprops){
      if (window.xprops.props.GELangCode) {
        langCode = window.xprops.props.GELangCode;
      }
    }
  }

  componentDidMount(){
    this.setState({ LoadingData: this.props.DataLoaded});

    if ((langCode) && (langCode !== 'en')) {
      (async () => {
        // Dynamically imported module (runtime)
        const { translateData } = await import("./geTranslationUtils");

        translateData(langCode, this.state.closeBtnText)
        .then((data) => {
          this.setState({ closeBtnText: data })
        });
      })();
    }
  }

  CloseComponent(){
    this.props.closeSearch();
  }

  render(){
    return(
      <div className="close-search-app mt-0">
        <div className="card-links" onClick={() => this.CloseComponent()} >
          <h6>{ this.state.closeBtnText }</h6>
        </div>
      </div>
    );
  }
}
