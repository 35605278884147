import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GeSearchComponent from './geSearchComponent';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

ReactDOM.render(<App {...window.xprops}/>, document.getElementById('ge-search-component'));

serviceWorker.unregister();
