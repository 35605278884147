import React from 'react';
import GeSearchResultsData from './geSearchResultData';
import GeSearchResultsDataAviation from './geSearchResultDataAviation';

const { REACT_APP_AVIATION_SEARCH_TYPE } = process.env;
var searchType = '';
var langCode = 'en';

class GESearchResults extends React.Component {
  constructor(props) {
    super(props);

    this.state={
      LoadingData: false
    }

    if (window.xprops){
      if (window.xprops.props.GESearchType) {
        searchType = window.xprops.props.GESearchType;
      }
      if (window.xprops.props.GELangCode) {
        langCode = window.xprops.props.GELangCode;
      }
    }
  }

  componentDidMount(){
    this.setState({LoadingData:this.props.DataLoaded});
  }

  SelectPopularSearch(selectedRecent){
    this.setState({LoadingData: false});
    this.props.SelectPopularSearch(selectedRecent);

  }

  render() {
    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      return (
        this.state.LoadingData === true ?
          this.props.SolarData ?
            this.props.SolarData.length > 0 ?
              <GeSearchResultsDataAviation
                domainfilter = {this.props.domainfilter}
                searchtext = {this.props.searchtext}
                SolarData = {this.props.SolarData}
                SelectPopularSearch = {this.SelectPopularSearch.bind(this)}
              />
            :
              <GeSearchResultsDataAviation
                domainfilter = {this.props.domainfilter}
                searchtext = {this.props.searchtext}
                SolarData = {[]}
                SelectPopularSearch = {this.SelectPopularSearch.bind(this)}
              />
          :
            null
        :
          null
      );
    }
    else {
//console.log(this.props);
      return (
        this.state.LoadingData === true ?
          this.props.SolarData ?
            this.props.SolarData.length > 0 ?
              <GeSearchResultsData
                domainfilter = {this.props.domainfilter}
                searchtext = {this.props.searchtext}
                SolarData = {this.props.SolarData}
                SelectPopularSearch = {this.SelectPopularSearch.bind(this)}
              />
            :
              <GeSearchResultsData
                domainfilter = {this.props.domainfilter}
                searchtext = {this.props.searchtext}
                SolarData = {[]}
                SelectPopularSearch = {this.SelectPopularSearch.bind(this)}
              />
          :
            null
        :
          null
      );
    }
  }
}

export default GESearchResults

