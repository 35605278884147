import React from 'react';
import ReactDOM from 'react-dom';
import GESearchResults from './geSearchResult'
import GEQuicklinksdata from './geQuicklinksData'
import GESolarHeroHeader from './geSolarHeroHeader'
import GESolarHero from './geSolarHero'
import GECloseComponent from './geCloseComponent';
import GEExternalLinksHeader from './geExternalLinksHeader';
import GESearchLabelHeader from './geSearchLabelHeader'
import {Menu,MenuItem,Highlighter, Typeahead}  from 'react-bootstrap-typeahead';
import data from './keywords.json';
import data_aviation from './keywordsAviation.json';
import GEBackComponent from './geBackComponent';
import './index.css';
import './unified_app.css';

const baseImgUrl =  process.env.REACT_APP_SOLAR_BASE_IMGURL;
const {REACT_APP_AVIATION_SEARCH_TYPE} = process.env;

var counter = -1;
var searchType = '';
var langCode = 'en';

class GEForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchAllGELabel: 'Search all GE',
      searchAllGEAerospaceLabel: 'Search all of GE Aerospace',
      suggestionsGELabel: 'Suggestions within GE.com',
      suggestionsGEAerospaceLabel: 'Suggestions within GE Aerospace',
      searchtext: '',
      domainfilter: '1',
      searchvalue: '',
      keywords: '',
      selected: [],
      Solarimg : '',
      SolarData : null,
      DataLoaded : false
    };
    this.SolarData = [];
    this.searchedText = "";
    this.SolarHeaderData = null;
    this.Solarimg = [];
    this.displayAutosuggest = true;
    this.displayHeroImage = true;

    if (window.xprops){
      if (window.xprops.props.GESearchType) {
        searchType = window.xprops.props.GESearchType;
      }
      if (window.xprops.props.GELangCode) {
        langCode = window.xprops.props.GELangCode;
      }

      if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
        (async () => {
          // Dynamically imported css (runtime)
          import('./aerospace_app.css').then().catch(() => {});
        })();
        var styleURL = document.getElementById('ge-search-stylesheet').href;
        document.getElementById('ge-search-stylesheet').href = styleURL.replace("ge_unified.style.css", "ge_aerospace.style.css");
        document.body.setAttribute("data-type", "ge-aerospace");
      }
    }
  }

  componentDidMount(){
    if ((langCode) && (langCode !== 'en')) {
      (async () => {
        // Dynamically imported module (runtime)
        const { translateData } = await import("./geTranslationUtils");

        if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
          translateData(langCode, this.state.searchAllGEAerospaceLabel)
          .then((data) => {
            this.setState({ searchAllGEAerospaceLabel: data })
          });

          translateData(langCode, this.state.suggestionsGEAerospaceLabel)
          .then((data) => {
            this.setState({ suggestionsGEAerospaceLabel: data })
          });
        }
        else {
          translateData(langCode, this.state.searchAllGELabel)
          .then((data) => {
            this.setState({ searchAllGELabel: data })
          });

          translateData(langCode, this.state.suggestionsGELabel)
          .then((data) => {
            this.setState({ suggestionsGELabel: data })
          });
        }
      })();
    }

    this.getKeywords();
    this.resizeFormComponent(true);

    if (window.xprops){
      if (window.xprops.props.UnifiedSearchDisplayBackButton === true){
        ReactDOM.render(<GEBackComponent
          HistoryBackSearch = {this.HistoryBackSearch.bind(this)}
          customMargin = {0} />,
          document.getElementById('ge-close-component')
        );
      }
      else{
        ReactDOM.render(<GECloseComponent
          closeSearch = {this.closeSearch.bind(this)}
          customMargin = {0} />,
          document.getElementById('ge-close-component')
        );
      }

      if (window.xprops.props.UnifiedSearchKeyword){
        this.LoadParams(window.xprops.props.UnifiedSearchKeyword);
      }
      else{
        if (document.getElementById('ge-form-wrapper')) {
          ReactDOM.render(<GEQuicklinksdata
            hideRecent = {false}
            initialHeight = {document.getElementById('ge-form-wrapper').clientHeight}
            SelectRecent = {this.SelectRecent.bind(this)}
            SelectPopularSearch = {this.SelectPopularSearch.bind(this)} />,
            document.getElementById('ge-search-component-related')
          );
        }
      }
    }
    else{
      if (document.getElementById('ge-form-wrapper')) {
        ReactDOM.render(<GEQuicklinksdata
          hideRecent = {false}
          initialHeight = {document.getElementById('ge-form-wrapper').clientHeight}
          SelectRecent = {this.SelectRecent.bind(this)}
          SelectPopularSearch = {this.SelectPopularSearch.bind(this)} />,
          document.getElementById('ge-search-component-related')
        );
      }
    }
  }

  componentDidUpdate(prevProps, prevState){
    document.onreadystatechange = function () {
      if (document.readyState === "complete") {
        if (window.xprops){
          window.xprops.props.EnableSearch();
        }
      }
    }

    if (this.state.DataLoaded === true) {
      if (this.SolarHeaderData) {
        this.renderSTresults(this.searchedText);
      }
    }
  }

  closeSearch(){
    this.selected = [];
    this.setState({searchtext: "" });
    this.setState({keywords: "" });
    this.setState({searchvalue: "" });
    this.searchedText = "";
    this.SolarHeaderData = null;
    this.componentDidMount();

    ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-related'));
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-hero-wrapper'));
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-stresults'));
    ReactDOM.unmountComponentAtNode(document.getElementById('external-search'));

    const instance = this._typeahead.getInstance()
    instance.clear()
    this.resizeFormComponent(true);
    ReactDOM.render(<GEQuicklinksdata
      hideRecent = {false}
      initialHeight = {document.getElementById('ge-form-wrapper').clientHeight}
      SelectRecent = {this.SelectRecent.bind(this)}
      SelectPopularSearch = {this.SelectPopularSearch.bind(this)}/>,
      document.getElementById('ge-search-component-related')
    );

    var p = document.querySelectorAll(".GEInputbox input");
    if (p[0]){
      p[0].value = '';
      p[0].disabled = false;
    }

    window.xprops.props.closeSearchComponent();
  }

  resizeFormComponent(useWindowHeight){
    var geFormheight = document.getElementById("ge-form-wrapper");

    if (window.xprops){
      if (useWindowHeight === true){
        geFormheight.style.height = (window.xprops.props.InitialHeight) + "px";
      }
      else{
        geFormheight.removeAttribute("style");
      }
    }
  }

  clearall (){
    this.displayHeroImage = false;
    this.setState({Solarimg: "" });
  }

  async getKeywords() {
    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      this.setState({keywords: data_aviation });
    }
    else {
      this.setState({keywords: data });
    }
  }

  getSolarData(searchstring) {
    var searchURL = "/solarsearch?keywords=" + encodeURIComponent('"' + searchstring.toLowerCase().replace(/[^\p{L}\p{N}\p{Sc}_@&.+\- ]/gui, '') + '"');
    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      fetch(searchURL)
      .then(response => response.json())
      .then(data_aviation => {
        document.getElementById('ge-form-wrapper').classList.add("nosolarheader");
        this.SolarHeaderData = data_aviation.response.docs;
        this.searchedText = searchstring;
        this.setState({DataLoaded: true});
        this.enableInputbox();
      })
      .catch(error => console.log('error', error));
    }
    else {
      fetch(searchURL)
      .then(response => response.json())
      .then(data => {
        ReactDOM.render(<GESolarHero SolarData = {data.response.docs} />,
          document.getElementById('ge-search-component-hero-wrapper')
        );
        this.SolarHeaderData = data.response.docs;
        this.searchedText = searchstring;
        this.setState({DataLoaded: true});
        this.enableInputbox();
      })
      .catch(error => console.log('error', error));
    }
  }

  getSolarHeaderImg(searchstring) {
    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      //Don't show and Images
    }
    else {
      this.setState({Solarimg: ""});
      var searchURL = "/solarheader?keywords=" + encodeURIComponent('"' + searchstring.toLowerCase().replace(/[^\p{L}\p{N}\p{Sc}_@&.+\- ]/gui, '') + '"');
      fetch(searchURL)
      .then(response => response.json())
      .then(data => {
        this.SolarData = data.response.docs;
        if (this.displayHeroImage === true) {
          if (data.response.docs[0]){
            this.setState({Solarimg: this.SolarData[0]});
          }
          else{
            this.setState({Solarimg: ""});
          }
        }
      })
      .catch(error => console.log('error', error));
    }
  }

  removeAutoSuggest(){
    this.displayAutosuggest = false;
    if (document.getElementById('ge-search-input')) {
      document.getElementById('ge-search-input').classList.add("hide-autosuggest");
    }
  }

  renderSTresults(searchstring){
    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      ReactDOM.render(<GESearchLabelHeader />,
        document.getElementById('search-results-label')
      );
      this.resizeFormComponent(false);

      ReactDOM.render(<GESearchLabelHeader />,
        document.getElementById('search-results-label')
      );

      ReactDOM.render(<GESearchResults
        domainfilter = {this.state.domainfilter}
        searchtext = {searchstring}
        SolarData = {this.SolarHeaderData}
        SelectRecent = {this.SelectRecent.bind(this)}
        SelectPopularSearch = {this.SelectPopularSearch.bind(this)}
        DataLoaded = {this.state.DataLoaded}/>,
        document.getElementById('ge-stresults')
      );
    }
    else {
      ReactDOM.render(<GESearchLabelHeader />,
        document.getElementById('search-results-label')
      );
      this.resizeFormComponent(false);

      ReactDOM.render(<GEExternalLinksHeader searchtext = {searchstring} />,
        document.getElementById('external-search')
      );

      ReactDOM.render(<GESearchLabelHeader />,
        document.getElementById('search-results-label')
      );

      ReactDOM.render(<GESearchResults
        domainfilter = {this.state.domainfilter}
        searchtext = {searchstring}
        SolarData = {this.SolarHeaderData}
        SelectRecent = {this.SelectRecent.bind(this)}
        SelectPopularSearch = {this.SelectPopularSearch.bind(this)}
        DataLoaded = {this.state.DataLoaded}/>,
        document.getElementById('ge-stresults')
      );
    }
  }

  disableInputbox(){
    var p = document.querySelectorAll(".GEInputbox input");

    if (p[0]){
      p[0].disabled = true;
      const instance = this._typeahead.getInstance();
      instance.blur();
    }
  }

  enableInputbox(){
    var p = document.querySelectorAll(".GEInputbox input");

    if (p[0]){
      p[0].disabled = false;
    }
  }

  LoadParams (searchstring) {
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-stresults'));
    this.getSolarData(searchstring);
    this.selected = [];
    this.selected.push(searchstring);
    this.setState({searchtext: searchstring});
  }

  HistoryBackSearch(){
    window.xprops.props.HistoryBack();
  }

  SubmitFormHandler (searchstring) {
    if(window.xprops){
      window.xprops.props.dataLayerPush("user_input", searchstring);
    }
    if (searchstring.length >= 3){
      this.disableInputbox();
      this.removeAutoSuggest();
      ReactDOM.unmountComponentAtNode(document.getElementById('ge-stresults'));
      this.getSolarData(searchstring);
      this.selected = [];
      this.selected.push(searchstring);
      this.setState({searchtext: searchstring});
    }
  }

  geOnBlur = (event) => {
    this.displayAutosuggest = false;
    this.setState({Solarimg: "" });
  }

  geOnFocus = (event) => {
    counter  = -1;

    this.setState({Solarimg: "" });
    this.SolarHeaderData = null;
    ReactDOM.unmountComponentAtNode(document.getElementById('external-search'));
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-hero-wrapper'));
    ReactDOM.unmountComponentAtNode(document.getElementById('search-results-label'));
    this.resizeFormComponent(true);

    if (event.target.value.length <= 0) {
      ReactDOM.render(<GEQuicklinksdata
        hideRecent = {false}
        initialHeight = {document.getElementById('ge-form-wrapper').clientHeight}
        SelectRecent = {this.SelectRecent.bind(this)}
        SelectPopularSearch = {this.SelectPopularSearch.bind(this)}/>,
        document.getElementById('ge-search-component-related')
      );
    }
    else{
      ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-related'));
    }
  }

  SelectRecent(selectedRecent){
    if(window.xprops){
      window.xprops.props.dataLayerPush("recent_search", selectedRecent);
    }

    this.disableInputbox();
    this.selected = [selectedRecent];
    this.displayHeroImage = false;
    this.setState({DataLoaded: false });
    this.setState({Solarimg: "" });
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-related'));
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-hero-wrapper'));
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-stresults'));
    this.getSolarData(selectedRecent);
    this.selected.push(selectedRecent);
    this.setState({searchtext: selectedRecent});
  }

  SelectPopularSearch(selectedRecent){
    if(window.xprops){
      window.xprops.props.dataLayerPush("popular_search", selectedRecent);
    }

    this.disableInputbox();
    this.selected = [selectedRecent];
    this.displayHeroImage = false;
    this.setState({DataLoaded: false });
    this.setState({Solarimg: "" });
    this.selected.push(selectedRecent);
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-related'));
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-hero-wrapper'));
    ReactDOM.unmountComponentAtNode(document.getElementById('ge-stresults'));
    this.getSolarData(selectedRecent)
    this.setState({searchtext: selectedRecent});
  }

  onSelected = (event) => {
    if (event[0]){
      if(window.xprops){
        window.xprops.props.dataLayerPush("auto_complete", event[0]);
      }

      this.selected = [event[0]];
      this.displayHeroImage = false;
      this.setState({Solarimg: "" });
      this.resizeFormComponent(true);
      ReactDOM.unmountComponentAtNode(document.getElementById('external-search'));
      ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-related'));
      ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-hero-wrapper'));
      ReactDOM.unmountComponentAtNode(document.getElementById('ge-stresults'));
      this.renderSTresults(event[0]);
      this.setState({searchtext: event[0]});
      this.getSolarData(event[0]);
    }
  }

  keypressed = (event) => {
    this.setState({Solarimg: "" });
    this.selected = [event];

    if (event.length >= 3){
      this.displayHeroImage = true;
      this.resizeFormComponent(true);
      ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-hero-wrapper'));
      ReactDOM.unmountComponentAtNode(document.getElementById('search-results-label'));
      ReactDOM.unmountComponentAtNode(document.getElementById('ge-search-component-related'));
      ReactDOM.unmountComponentAtNode(document.getElementById('external-search'));
    }
    else if (event.length === 0){
      const instance = this._typeahead.getInstance();
      instance.blur();
      instance.focus();

      ReactDOM.render(<GEQuicklinksdata
        hideRecent = {false}
        initialHeight = {document.getElementById('ge-form-wrapper').clientHeight}
        SelectRecent = {this.SelectRecent.bind(this)}
        SelectPopularSearch = {this.SelectPopularSearch.bind(this)} />,
        document.getElementById('ge-search-component-related')
      );
    }
  }

  onMouseEnterHandler = (event) => {
    this.setState({Solarimg: "" });
    if (this.displayHeroImage === true){
      this.getSolarHeaderImg(event.currentTarget.textContent);
    }
  }

  onMouseLeaveHandler = (event) => {
  }

  render() {
//console.log('deployment log: 03/30/2022 - 3');

    if (searchType === `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      return (
        this.state.keywords.length > 0 ?
          <form onSubmit = {this.mySubmitHandler} ref = "gesearchform">
            <div className = "row">
              <div className = "col-12">
                <div id = "search-results-label"></div>
                <div className = "GEInputbox" >
                  <h3><div className = "ficon-search float-right"></div></h3>
                  <Typeahead
                    selected = {this.selected}
                    ref = {(ref) => this._typeahead = ref}
                    id = "ge-search-input"
                    bsSize = {'lg'}
                    maxResults = {5}
                    minLength = {3}
                    paginate = {false}
                    placeholder = { this.state.searchAllGEAerospaceLabel }
                    options = {this.state.keywords}
                    renderMenu = {(results, menuProps) => (
                      <Menu {...menuProps} onKeyDown = {this.onKeyDown}>
                        {results.length > 0 && (<div className = "autosuggest caption">{ this.state.suggestionsGEAerospaceLabel }</div>)}

                        {results.map((result, index) => (
                          <MenuItem
                            className = "body-1"
                            option = {result}
                            position = {index}
                            onMouseEnter = {this.onMouseEnterHandler}
                            onMouseLeave = {this.onMouseLeaveHandler}
                            key = {index}
                            data-attribute-textval = {result}
                          >
                            <Highlighter search = {menuProps.text}>
                              {result}
                            </Highlighter>
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                    align = {"left"}
                    maxHeight = {'500px'}
                    onFocus = {this.geOnFocus}
                    onBlur = {this.geOnBlur}
                    onInputChange = {this.keypressed}
                    onChange = {this.onSelected}
                    onKeyDown = {(e) => {
                      if (e.keyCode === 13){
                        if (counter === -1) {
                          this.SubmitFormHandler(e.currentTarget.value);
                        }
                      }
                      else if (e.keyCode === 38) {
                        let p = document.querySelectorAll("ul.show li");
                        counter = counter-1;

                        if (counter === -1) {
                          this.setState({Solarimg: ""});
                        }
                        else if (counter < -1){
                          counter = p.length - 1;
                        }

                        if (p[counter]){
                          this.getSolarHeaderImg(p[counter].attributes['data-attribute-textval'].value);
                        }
                        else{
                          this.setState({Solarimg: ""});
                        }
                      }
                      else if (e.keyCode === 40) {
                        let p = document.querySelectorAll("ul.show li");
                        counter = counter + 1;
                        if (counter === p.length) {
                          this.setState({Solarimg: ""});
                        }
                        else if (counter > p.length){
                          counter = 0;
                        }
                        if (p[counter]){
                          this.getSolarHeaderImg(p[counter].attributes['data-attribute-textval'].value);
                        }
                      }
                      else if (e.keyCode === 27) {
                        this.closeSearch();
                      }
                    }}
                  />

                  <div id = "external-search"></div>

                </div>
              </div>
            </div>
          </form>
        :
          null
      );
    }
    else {
      return (
        this.state.keywords.length > 0 ?
          <form onSubmit = {this.mySubmitHandler} ref = "gesearchform">
            <div className = "row">
              <div className = "col-12">
                <div id = "search-results-label"></div>
                <div className = "GEInputbox" >
                  <h3><div className = "ficon-search float-right"></div></h3>
                  <Typeahead
                    selected = {this.selected}
                    ref = {(ref) => this._typeahead = ref}
                    id = "ge-search-input"
                    bsSize = {'lg'}
                    maxResults = {5}
                    minLength = {3}
                    paginate = {false}
                    placeholder = { this.state.searchAllGELabel }
                    options = {this.state.keywords}
                    renderMenu = {(results, menuProps) => (
                      <Menu {...menuProps} onKeyDown = {this.onKeyDown}>
                        {results.length > 0 && (<div className = "autosuggest caption">{ this.state.suggestionsGELabel }</div>)}

                        {results.map((result, index) => (
                          <MenuItem
                            className = "body-1"
                            option = {result}
                            position = {index}
                            onMouseEnter = {this.onMouseEnterHandler}
                            onMouseLeave = {this.onMouseLeaveHandler}
                            key = {index}
                            data-attribute-textval = {result}
                          >
                            <Highlighter search = {menuProps.text}>
                              {result}
                            </Highlighter>
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                    align = {"left"}
                    maxHeight = {'500px'}
                    onFocus = {this.geOnFocus}
                    onBlur = {this.geOnBlur}
                    onInputChange = {this.keypressed}
                    onChange = {this.onSelected}
                    onKeyDown = { (e) => {
                      if (e.keyCode === 13){
                        if (counter === -1) {
                          this.SubmitFormHandler(e.currentTarget.value);
                        }
                      }
                      else if (e.keyCode === 38) {
                        let p = document.querySelectorAll("ul.show li");
                        counter = counter - 1;

                        if (counter === -1) {
                          this.setState({Solarimg: ""});
                        }
                        else if (counter < -1){
                          counter = p.length - 1;
                        }
                        if (p[counter]){
                          this.getSolarHeaderImg(p[counter].attributes['data-attribute-textval'].value);
                        }
                        else{
                          this.setState({Solarimg: ""});
                        }
                      }
                      else if (e.keyCode === 40) {
                        let p = document.querySelectorAll("ul.show li");
                        counter = counter + 1;
                        if (counter === p.length) {
                          this.setState({Solarimg: ""});
                        }
                        else if (counter > p.length){
                          counter = 0;
                        }
                        if (p[counter]){
                          this.getSolarHeaderImg(p[counter].attributes['data-attribute-textval'].value);
                        }
                      }
                      else if (e.keyCode === 27) {
                        this.closeSearch();
                      }
                    }}
                  />

                  <div id = "external-search"></div>

                  {
                    this.SolarData.length > 0 && this.displayHeroImage === true && (
                      <GESolarHeroHeader
                        baseImgUrl = {baseImgUrl}
                        solarheaderimg = {this.state.Solarimg}
                        SolarData = {this.SolarData}
                        displayImage = {this.displayHeroImage}
                        clearall = {this.clearall.bind(this)}
                      />
                    )
                  }
                </div>
              </div>
            </div>
          </form>
        :
          null
      );
    }
  }
}

export default GEForm

