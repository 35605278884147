import React from 'react';
import GESearchNoResultData from './geSearchNoResultData';
import SkeletonCards from './geResultSkeleton';
import geDomainFilters from './domainFilters.json';
var CardResults = [];

export default class GESearchResultsStructure extends React.Component {

  constructor(props) {
    super(props);
    this.ResponseDataInfo = [];
    this.domainFilters = geDomainFilters;
    this.domainfilter = "";
    this.domainfilterDesc = "";
    this.searchtext = "";
    this.state = {
      GetResponseData: [],
      LoadingData: false
    }
  }

  componentDidUpdate(){
    if (this.props.Dataloaded === true ){

      if (this.props.STresponseData){
        // if (this.state.GetResponseData!==this.props.STresponseData.records.page){
        this.ResponseDataInfo = this.props.STresponseData.info.page;
        this.domainfilter = this.props.domainfilter;
        this.searchtext = this.props.searchtext;
        if (this.state.GetResponseData !== this.props.STresponseData.records.page){
          this.setState({GetResponseData: this.props.STresponseData.records.page});
        }

        this.domainfilterDesc = " across GE.COM";

        if (this.domainFilters.length > 0) {
          for (var i = 0; i < this.domainFilters.length; i++) {
            if (String(this.domainfilter) === String(this.domainFilters[i].domainFilter)) {
              this.domainfilterDesc = " within " + this.domainFilters[i].label;
              break;
            }
          }
        }

      }
    }
  }

  openLinks = (event) => {
    if (event.currentTarget.attributes['data-link-src']){
      window.xprops.props.Openlinks(event.currentTarget.attributes['data-link-src'].value);
    }
  }

  SelectPopularSearch(selectedRecent){
    this.props.SelectPopularSearch(selectedRecent);
  }

  render(){
    CardResults = this.state.GetResponseData.map((item, i) => (
      <div className = "card" key = {i}>
        <div className = "card-body">
          <div className = "card-title">
            <span data-link-src = {item.url} onClick = {this.openLinks}>
            {
              item.highlight.title ?
                <h3
                dangerouslySetInnerHTML = {{__html: item.highlight.title}}
                />
              :
                <h3>{item.title}</h3>
            }
            </span>
          </div>
          {item.highlight.body ?
            item.highlight.body.replace(/�/g, "").length > 313 ?
              <div dangerouslySetInnerHTML =
                {{__html: item.highlight.body.replace(/�/g, "").substring(0, 313) + " ..."}}
              />
            :
              <div dangerouslySetInnerHTML =
                {{__html: item.highlight.body.replace(/�/g, "")}}
              />
          :
            item.body.replace(/�/g, "").length > 313 ?
              item.body.replace(/�/g, "").substring(0, 313) + " ..."
            :
              item.body.replace(/�/g, "").substring(0, 313)
          }
          <div className = "card-readmore d-lg-none">
            <span className = "arrow-right ficon-lg-arrow-right"></span>
          </div>
        </div>
      </div>
    ));

    return(
      this.props.Dataloaded === false ?
        <SkeletonCards />
      :
        //CardResults.length
        CardResults.length > 0 ?
          <div>
            <div className = "results-header caption">
              Showing {(this.ResponseDataInfo.current_page * 10) - 9} - {this.ResponseDataInfo.total_result_count > 10 ? this.ResponseDataInfo.current_page * 10 : this.ResponseDataInfo.total_result_count} of {this.ResponseDataInfo.total_result_count} results {this.domainfilterDesc} for <strong>{this.props.searchtext}</strong>
            </div>
            {CardResults}
          </div>
        :
          <GESearchNoResultData
            SolarData = {this.props.SolarData}
            searchtext = {this.searchtext}
            SelectPopularSearch = {this.SelectPopularSearch.bind(this)}
          />
    );
  }
}
