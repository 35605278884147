import * as zoid from "zoid/dist/zoid.frameworks";

var GeSearchComponent = zoid.create({
  tag: "ge-search-component",
  url: process.env.REACT_APP_URL + "/index.html",
  autoResize: {
		width: false,
		height: true,
		element: ".ge-app-root"
	},
	allowedParentDomains: process.env.AllOWED_DOMAINS
});

export default GeSearchComponent
