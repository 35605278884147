import React from 'react';
import Iframe from 'react-iframe'

const baseImgUrl = process.env.REACT_APP_SOLAR_BASE_IMGURL;
const stockCard = process.env.REACT_APP_CUSTOM_STOCK_CARD;
var RecentSearch
var p
export default class GESolarHero extends React.Component {

  constructor(props) {
    super(props);
    this.displayIfame = false;
  }
  openLinks = (event) => {
    if (event.currentTarget.attributes['data-link-src']) {
      window.xprops.props.Openlinks(event.currentTarget.attributes['data-link-src'].value);
    }
  }

  CreateMarkup(markup) {
    return {__html: markup};
  }

  render(){
    if (this.props.SolarData.length > 0 ){
      if (!this.props.SolarData[0].tm_X3b_en_field_hero_field_link_title){
        this.props.SolarData[0].tm_X3b_en_field_hero_field_link_title = [];
      }
      if ((!this.props.SolarData[0].sm_field_hero_image_url) &&
        (Array.isArray(this.props.SolarData[0].tm_X3b_en_field_hero_title)) &&
        (this.props.SolarData[0].tm_X3b_en_field_hero_title[0] === "General Electric Company") &&
        (Array.isArray(this.props.SolarData[0].tm_X3b_en_field_hero_field_link_title)) &&
        (this.props.SolarData[0].tm_X3b_en_field_hero_field_link_title[0] === "More on GE Stock")
      ){
        this.displayIfame = true;
      }
      RecentSearch = this.props.SolarData[0].tm_X3b_en_field_hero_field_link_title.map((item, i) => (
        <div key = {i} className = "btn button-primary active"
          data-link-src = {Array.isArray(this.props.SolarData[0].tm_X3b_en_field_hero_field_link) ? this.props.SolarData[0].tm_X3b_en_field_hero_field_link[i] : ''}
          onClick = {this.openLinks}>
          {item}
        </div>
      ));

      p = document.getElementById('ge-form-wrapper');
      p.classList.remove("nosolarheader");
    }
    else {
      p = document.getElementById('ge-form-wrapper');
      p.classList.add("nosolarheader");
    }


    return (
      this.props.SolarData.length > 0 ?
        <div id = "ge-search-component-hero" data-card-id = {Array.isArray(this.props.SolarData[0].tm_X3b_en_field_hero_title) ? this.props.SolarData[0].tm_X3b_en_field_hero_title[0] : ''}>
          <div className = "container-fluid-custom">
            <div className = "row">
              <div className = "col-lg-12 col-md-12 col-sm-12 pr-0 pl-0">
              {this.displayIfame === true &&
                (
                  <Iframe
                    url = {stockCard}
                    width = "100%"
                    height = "870px"
                    id = "geStockframe"
                    className = "geStockframe"
                    display = "initial"
                    position = "relative"
                  />
                )
              }

              {this.props.SolarData[0].sm_field_hero_image_url ||
                this.props.SolarData[0].tm_X3b_en_field_hero_title ||
                this.props.SolarData[0].tm_X3b_en_field_hero_html_body ?
                this.displayIfame === false &&
                (
                  <div className = "position-relative banner">
                    {this.props.SolarData[0].sm_field_hero_image_url &&
                      (
                        <div className = "hero-img-wrapper">
                          <div className = "hero-gradient2"></div>
                          <img
                            src = {baseImgUrl + (Array.isArray(this.props.SolarData[0].sm_field_hero_image_url) ? this.props.SolarData[0].sm_field_hero_image_url[0] : '')}
                            alt = {this.props.SolarData[0].tm_X3b_en_field_hero_image_alt}
                          />
                        </div>
                      )
                    }
                    <div className = "hero-description-wrapper">
                      <div className = "hero-description">
                        <h2>{Array.isArray(this.props.SolarData[0].tm_X3b_en_field_hero_title) ? this.props.SolarData[0].tm_X3b_en_field_hero_title[0] : ''}</h2>
                        <div
                          className = "body-1"
                          dangerouslySetInnerHTML = {this.CreateMarkup(this.props.SolarData[0].tm_X3b_en_field_hero_html_body)}
                        />
                        <div className = "learnMore">
                          {RecentSearch}
                        </div>
                      </div>
                    </div>
                  </div>
                )
                : null
              }
              </div>
            </div>
          </div>
        </div>
        :
        null
    )
  }
}



