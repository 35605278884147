import React from 'react';
var langCode = 'en';

export default class GESearchLabelHeader extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      searchResultsLabel: 'SEARCH RESULTS'
    };

    if (window.xprops){
      if (window.xprops.props.GELangCode) {
        langCode = window.xprops.props.GELangCode;
      }
    }
  }

  componentDidMount(){
    if ((langCode) && (langCode !== 'en')) {
      (async () => {
        // Dynamically imported module (runtime)
        const { translateData } = await import("./geTranslationUtils");

        translateData(langCode, this.state.searchResultsLabel)
        .then((data) => {
          this.setState({ searchResultsLabel: data })
        });
      })();
    }
  }

  render() {
    return(
      <React.Fragment>
        <h5 className="searchResultLabel">{ this.state.searchResultsLabel }</h5>
      </React.Fragment>
    )
  }
}



