import React from 'react';
const {REACT_APP_AVIATION_SEARCH_TYPE} = process.env;

const baseImgUrl = process.env.REACT_APP_SOLAR_BASE_IMGURL;
var SolrCardSide = [];
var searchType = '';

export default class GESolarSearchResult extends React.Component {

  CreateMarkup(markup){
    return  {__html: markup};
  }
  openLinks = (event) => {
    if (event.currentTarget.attributes['data-link-src']){
      window.xprops.props.Openlinks(event.currentTarget.attributes['data-link-src'].value);
    }
  }

  render() {

    if (window.xprops){
      if (window.xprops.props.GESearchType) {
        searchType = window.xprops.props.GESearchType;
      }
    }

    if (searchType !== `${REACT_APP_AVIATION_SEARCH_TYPE}`) {
      if (this.props.SolarData.length > 0){
        if (this.props.SolarData[0].tm_X3b_en_field_right_card_html_body){
          SolrCardSide = this.props.SolarData[0].tm_X3b_en_field_right_card_html_body.map((item, i) => (
            <div className = "card" key = {i}>
              {
                this.props.SolarData[0].sm_field_right_card_image_url &&
                this.props.SolarData[0].sm_field_right_card_image_url[i] && (
                  <div className = "img-wrapper">
                    <img className = "card-img-top" src = {baseImgUrl + ((this.props.SolarData[0].sm_field_right_card_image_url) ? this.props.SolarData[0].sm_field_right_card_image_url[i] : '')}
                      alt = {this.props.SolarData[0].tm_X3b_en_field_right_card_image_alt ? this.props.SolarData[0].tm_X3b_en_field_right_card_image_alt[i] : ''} />
                    <div className = "gradient-overlays"></div>
                  </div>
                )
              }
              <div className = "card-body">
                <div className = "SRresults-header"></div>
                <div className = "header-desc">
                  {
                    this.props.SolarData[0].tm_X3b_en_field_right_card_title &&
                    (<h3>{this.props.SolarData[0].tm_X3b_en_field_right_card_title[i]}</h3>)
                  }
                  <div className = "body-2 "
                    dangerouslySetInnerHTML={this.CreateMarkup(item)}
                  />
                  {
                    this.props.SolarData[0].sm_field_right_card_link_titles && (
                      <div className = "card-links">
                        <div data-link-src = {this.props.SolarData[0].sm_field_right_card_links[i]}
                          onClick = {this.openLinks}>
                          <h6>{this.props.SolarData[0].sm_field_right_card_link_titles[i]}</h6>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          ));
        }
      }

      return(
        this.props.SolarData.length > 0 ?
          <div className = "SolarCardsWrapper">
            <div className = "">
              <div className = "SolarCards">
                {SolrCardSide}
              </div>
            </div>
          </div>
          :
          null
      );

    }
    else {
      return null;
    }

  }
}
